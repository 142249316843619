<template>
  <div class="kcz">
    <div class="kcz-left">
      <img :src="logo" class="logo" />
      快艺学
    </div>
    <div class="kcz-right">
      <wx-open-launch-app v-if="isWeixin && is_version" id="launch-btn" :appid="appId" :extinfo="extinfo" class="open-launch-app" @error="openError">
        <script type="text/wxtag-template">
          <style>.btn {
                  width:100%;
                  height:30px;
            }</style>
            <div class="btn"></div>
        </script>
      </wx-open-launch-app>
      <div class="linkApp" @click="linkApp">立即进入</div>
    </div>
  </div>
</template>

<script>
  import linkApp from "@/utils/linkApp";
  import { isWeixin } from "@/utils/common";
  import { wxShare, init, is_version } from "@/utils/wxShare";
  export default {
    name: "kcz",
    data() {
      return {
        logo: require("@/assets/images/logo.png"),
        appId: "wx720bb4d01c924127",
        isWeixin: isWeixin(),
        is_version: is_version(),
      };
    },
    props: {
      path: {
        type: String,
        default: "",
      },
      id: {
        type: [String, Number],
        default: "",
      },
    },
    computed: {
      extinfo() {
        return JSON.stringify({
          path: "/" + this.path,
          id: this.id,
        });
      },
    },
    methods: {
      linkApp() {
        const data = {
          path: this.path,
          param: this.id,
        };
        linkApp(data);
      },
      openError() {
        this.$router.push({ name: "index" });
      },
    },
    created() {},
  };
</script>

<style lang="scss" scoped>
  //快艺学立即体验
  .kcz {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0 20px;
    height: 50px;
    box-shadow: 0 0 2px #eee;
    .kcz-left {
      color: #373737;
      font-size: 20px;
      .logo {
        width: 30px;
        height: 30px;
      }
    }
    .kcz-right {
      width: 100px;
      border-radius: 4px;
      border: 1px solid #f04040;
      text-align: center;
      font-size: 14px;
      font-weight: 500;
      height: 30px;
      line-height: 30px;
      color: #f04040;
      opacity: 1;
      position: relative;
      // &:hover {
      //   background-color: #f04040;
      //   color: #fff;
      // }
    }
  }
</style>

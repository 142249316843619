<template>
  <div>
    <div class="dialog" @touchmove.prevent v-show="dialogShow">
      <div class="dialog-content">
        <div class="dialog-title">继续浏览内容</div>
        <div class="dialog-line">
          <div class="dialog-left">
            <img :src="link1" />
            快艺学
          </div>
          <div class="dialog-right active">
            <wx-open-launch-app v-if="isWeixin && is_version" :appid="appId" :extinfo="extinfo" class="open-launch-app" @error="openError">
              <script type="text/wxtag-template">
                <style>.btn {
                    width:100%;
                    height: 35px
                  }</style>
                  <div class="btn"></div>
              </script>
            </wx-open-launch-app>
            <div class="linkApp" @click="linkApp">
              打开
            </div>
          </div>
        </div>
        <div class="dialog-line weixin" v-if="isWeixin">
          <div class="dialog-left">
            <img :src="link2" />
            微信
          </div>
          <div class="dialog-right dialogClose ripple" @click="dialogClose">
            继续
          </div>
        </div>
        <div class="dialog-line liulanqi" v-else>
          <div class="dialog-left">
            <img :src="link3" />
            浏览器
          </div>
          <div class="dialog-right dialogClose ripple" @click="dialogClose">
            继续
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import linkApp from "@/utils/linkApp";
  import { isWeixin } from "@/utils/common";
  import { wxShare, init, is_version } from "@/utils/wxShare";
  export default {
    name: "tanchuang",
    props: {
      path: {
        type: String,
        default: "",
      },
      id: {
        type: [String, Number],
        default: "",
      },
    },
    data() {
      return {
        appId: "wx720bb4d01c924127",
        link1: require("@/assets/images/logo.png"),
        link2: require("@/assets/images/link2.png"),
        link3: require("@/assets/images/link3.png"),
        isWeixin: isWeixin(),
        is_version: is_version(),
        dialogShow: true,
      };
    },
    computed: {
      extinfo() {
        return JSON.stringify({
          path: "/" + this.path,
          id: this.id,
        });
      },
    },

    methods: {
      openError() {
        this.$router.push({ name: "index" });
      },
      linkApp() {
        const data = {
          path: this.path,
          param: this.id,
        };
        linkApp(data);
      },
      dialogClose() {
        this.dialogShow = false;
      },
    },
    created() {
      if (this.isWeixin) {
        // const oScript = document.createElement("script");
        // oScript.type = "text/javascript";
        // oScript.src = "https://res2.wx.qq.com/open/js/jweixin-1.6.0.js";
        // oScript.onload = init();
        // document.body.appendChild(oScript);
      } else {
      }
    },
  };
</script>

<style lang="scss" scoped>
  //刚分享的弹窗
  .dialog {
    position: fixed;
    z-index: 999999;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.6);
    width: 100%;
    height: 100%;
    height: 100vh;
    .dialog-content {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      background: #fff;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      padding: 0.4rem 0.53rem;
      .dialog-title {
        height: 0.53rem;
        font-size: 18px;
        line-height: 1.5;
        color: #373737;
      }
      .dialog-line {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 15px 0;
        border-bottom: 1px solid #eee;
        &:nth-last-child(1) {
          border: none;
        }
        .dialog-left {
          font-size: 16px;
          color: #373737;
          display: flex;
          align-items: center;
          img {
            width: 50px;
            height: 50px;
            margin-right: 10px;
          }
        }
        .dialog-right {
          position: relative;
          width: 100px;
          text-align: center;
          background: #f0f0f0;
          color: #626262;
          font-size: 16px;
          border-radius: 4px;
          height: 35px;
          line-height: 35px;
        }

        .active {
          background: #f04040;
          color: #fff;
        }
      }
    }
  }
</style>
